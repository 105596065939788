import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import facebook from '../images/facebook.jpg';
import instagram from '../images/instagram.jpg';
import linkedin from '../images/linkdin.jpg';
import twitter from '../images/twitter.jpg';
import { useLocation, useNavigate } from "react-router-dom";

// const customStyles = {
//     content: {
//         top: '50%',
//         left: '50%',
//         right: 'auto',
//         bottom: 'auto',
//         marginRight: '-50%',
//         transform: 'translate(-50%, -50%)',
//         overflow: "auto",
//     },
// };

function CustomerDetail(props) {
    const histroy = useNavigate();
    const [custData, setCustData] = useState([]);
    const location = useLocation();
    let newArray = [];
    // const { customerData } = route.params;
    useEffect(() => {
        // console.log(" location ", location);
        console.log(" location customerData ", location.state.customerData.length);
        console.log(" location customerData ", typeof location.state.customerData[0]);
        // setCustData([location.state.customerData[0]]);
        setCustData(location.state.customerData);
    }, [location])

    useEffect(() => {
        if (custData[0] && custData[0].customerId) {
            console.log(" custData ", custData[0].customerId);
        }
    }, [custData])

    const [modalIsOpen, setIsOpen] = useState(false);
    const [singleCustData, setSingleCustData] = useState([]);

    console.log("custData----",custData);

    function openModal(indexNumber) {
        // console.log("indexNumber----",indexNumber);
        let tempData = [];
        tempData.push(custData[indexNumber]);
        // console.log("tempData----",tempData);
        setSingleCustData(tempData);
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    function MobileNumber(props) {
        const stringMng = props.count;
        
        console.log(" props ----- ", props );
        if (props.value != '0' && props.value != 'NA' && props.value != '-' && props.value != '----' && props.value != 'NULL') {
            return <>
            {props.index === props.count ? props.value : props.value.concat(",")} </>;
        }
        else {
            return <></>
        }

    }


    return (
        <div className='mainsecondpage'>
            <div className="mngbtnherprop">
                <button className='backbtn' onClick={() => histroy(-1)}><span>&larr;</span>Back</button>
                <h4 className='resultfound'>We have found {custData.length} Matches</h4>
            </div>
            <div className="container-fluid maincontainerprops">
                <div className='klimotable'>
                    <table>
                        <thead className='headertable'>
                            <tr>
                                <th>Sr. No.</th>
                                <th>Name</th>
                                <th>Contact Details</th>
                                <th>Address</th>
                                <th>Email ID</th>
                            </tr>
                        </thead>
                        <tbody>
                            {custData.map((cus, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td><span onClick={() => openModal(index)}>{cus.fullName}</span></td>
                                    <td>
                                        {cus.phone.map((phone, index) => (
                                            <MobileNumber key={index} index={index+1} count={cus.phone.length} value={phone} />
                                        ))}
                                    </td>
                                    {/* <td>{cus.phone.str >= 10 ? cus.phone.join() : "-"}</td> */}

                                    {cus.address && cus.address[0] && (
                                        <td>
                                            {cus.address[0].address}
                                        </td>
                                    )}
                                    <td>{cus.workEmail ? cus.workEmail : ''}</td>
                                </tr>
                            ))}


                            {/* <tr>
                            <td>1</td>
                            <td onClick={openModal}>Demo</td>
                            <td>Demo8888</td>
                            <td>xzy delhi</td>
                            <td>abc@gmail.com</td>
                        </tr> */}
                        </tbody>
                    </table>
                </div>

                {/* ==================== Modal Here =============== */}

                <div>
                    <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                        className="modalstylechange"
                        contentLabel="Example Modal"
                        ariaHideApp={false}
                    >

                        {singleCustData && singleCustData.map((cus, index) => (
                            <div key={index} className="container designlayout">

                                {/* ================= Name Row =========== */}
                                <div className="row">
                                    <div className="col-md-2">
                                        <h4 className='datanamfo'><b>Name</b></h4>
                                    </div>
                                    <div className="col-md-1"><h4>:</h4></div>
                                    <div className="col-md-9">
                                        <h4 className='datanamfo'>{cus.fullName}</h4>
                                    </div>
                                </div>

                                {/* ================= Name Row =========== */}

                                {/* ================= Telephone Row =========== */}
                                {cus.phone && cus.phone.length > 0 && (
                                    <div className="row">
                                        <div className="col-md-2">
                                            <h4 className='datanamfo'><b>Telephone</b></h4>
                                        </div>
                                        <div className="col-md-1"><h4>:</h4></div>
                                        <div className="col-md-9">
                                            <h4 className='datanamfo'>
                                                {cus.phone.join()}
                                            </h4>
                                        </div>
                                    </div>
                                )}

                                {/* ================= Telephone Row =========== */}

                                {/* ================= Address Row =========== */}
                                {cus.address.map((cus, ak) => {
                                    return <div key={ak} className="row">

                                        <div className="col-md-2">
                                            <h4 className='datanamfo'><b>Address {ak + 1}</b></h4>
                                        </div>
                                        <div className="col-md-1"><h4>:</h4></div>
                                        <div className="col-md-9">

                                            <h4 className='datanamfo'>{cus.address}</h4>

                                        </div>

                                    </div>

                                })}
                                {/* ================= Address Row =========== */}

                                {/* ================= Work Address Row =========== */}
                                <div className="row">
                                    <div className="col-md-2">
                                        <h4 className='datanamfo'><b>Work Address</b></h4>
                                    </div>
                                    <div className="col-md-1"><h4>:</h4></div>
                                    <div className="col-md-9">
                                        <h4 className='datanamfo'>{cus.workAddress ? cus.workAddress : ''}</h4>

                                    </div>
                                </div>

                                {/* ================= Work Address Row =========== */}


                                {/* ================= Work Email Row =========== */}
                                <div className="row">
                                    <div className="col-md-2">
                                        <h4 className='datanamfo'><b>Work Email</b></h4>
                                    </div>
                                    <div className="col-md-1"><h4>:</h4></div>
                                    <div className="col-md-9">
                                        <h4 className='datanamfo'>{cus.workEmail ? cus.workEmail : ''}</h4>
                                    </div>
                                </div>

                                {/* ================= Work Email Row =========== */}

                                {/* =============================================== */}

                                <hr />

                                {/* =============================================== */}

                                {/* ================== Family Details ============ */}

                                <h4 className='resultfound'>Family Details</h4>
                                {/* ================= Father Details =================== */}
                                <div className="row">
                                    {/* ======== Left row ========= */}
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <h4 className='datanamfo'><b>Father Name</b></h4>
                                            </div>
                                            <div className="col-md-1"><h4>:</h4></div>
                                            <div className="col-md-7">
                                                <h4 className='datanamfo'> {cus.fatherName} </h4>
                                            </div>
                                        </div>
                                    </div>

                                    {/* ======== Left row ========= */}

                                    {/* ======== right row ========= */}
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <h4 className='datanamfo'><b>Contact Number</b></h4>
                                            </div>
                                            <div className="col-md-1"><h4>:</h4></div>
                                            <div className="col-md-7">
                                                <h4 className='datanamfo'>{cus.fatherName}</h4>
                                            </div>
                                        </div>
                                    </div>

                                    {/* ======== right row ========= */}


                                </div>

                                {/* ================= Father Details =================== */}

                                {/* ================= Mother Details =================== */}
                                <div className="row">
                                    {/* ======== Left row ========= */}
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <h4 className='datanamfo'><b>Mother Name</b></h4>
                                            </div>
                                            <div className="col-md-1"><h4>:</h4></div>
                                            <div className="col-md-7">
                                                <h4 className='datanamfo'>{cus.motherName}</h4>
                                            </div>
                                        </div>
                                    </div>

                                    {/* ======== Left row ========= */}

                                    {/* ======== right row ========= */}
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <h4 className='datanamfo'><b>Contact Number</b></h4>
                                            </div>
                                            <div className="col-md-1"><h4>:</h4></div>
                                            <div className="col-md-7">
                                                <h4 className='datanamfo'>{cus.motherName}</h4>
                                            </div>
                                        </div>
                                    </div>

                                    {/* ======== right row ========= */}


                                </div>

                                {/* ================= Mother Details =================== */}

                                {/* ================== Family Details ============ */}

                                {/* ==================================== */}

                                <hr />

                                {/* ========================== */}


                                {/* ================== Other Family Details ============ */}

                                <h4 className='resultfound'>Other Family Details</h4>
                                {/* ================= Wife Details =================== */}
                                <div className="row">
                                    {/* ======== Left row ========= */}
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <h4 className='datanamfo'><b>Spouse Name</b></h4>
                                            </div>
                                            <div className="col-md-1"><h4>:</h4></div>
                                            <div className="col-md-7">
                                                <h4 className='datanamfo'></h4>
                                            </div>
                                        </div>
                                    </div>

                                    {/* ======== Left row ========= */}

                                    {/* ======== right row ========= */}
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <h4 className='datanamfo'><b>Contact Number</b></h4>
                                            </div>
                                            <div className="col-md-1"><h4>:</h4></div>
                                            <div className="col-md-7">
                                                <h4 className='datanamfo'></h4>
                                            </div>
                                        </div>
                                    </div>

                                    {/* ======== right row ========= */}


                                </div>

                                {/* ================= Wife Details =================== */}

                                {/* ================= Son Details =================== */}
                                <div className="row">
                                    {/* ======== Left row ========= */}
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <h4 className='datanamfo'><b>Son Name</b></h4>
                                            </div>
                                            <div className="col-md-1"><h4>:</h4></div>
                                            <div className="col-md-7">
                                                <h4 className='datanamfo'></h4>
                                            </div>
                                        </div>
                                    </div>

                                    {/* ======== Left row ========= */}

                                    {/* ======== right row ========= */}
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <h4 className='datanamfo'><b>Contact Number</b></h4>
                                            </div>
                                            <div className="col-md-1"><h4>:</h4></div>
                                            <div className="col-md-7">
                                                <h4 className='datanamfo'></h4>
                                            </div>
                                        </div>
                                    </div>

                                    {/* ======== right row ========= */}


                                </div>

                                {/* ================= Son Details =================== */}

                                {/* ================= Daughter Details =================== */}
                                <div className="row">
                                    {/* ======== Left row ========= */}
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <h4 className='datanamfo'><b>Daughter Name</b></h4>
                                            </div>
                                            <div className="col-md-1"><h4>:</h4></div>
                                            <div className="col-md-7">
                                                <h4 className='datanamfo'></h4>
                                            </div>
                                        </div>
                                    </div>

                                    {/* ======== Left row ========= */}

                                    {/* ======== right row ========= */}
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <h4 className='datanamfo'><b>Contact Number</b></h4>
                                            </div>
                                            <div className="col-md-1"><h4>:</h4></div>
                                            <div className="col-md-7">
                                                <h4 className='datanamfo'></h4>
                                            </div>
                                        </div>
                                    </div>

                                    {/* ======== right row ========= */}


                                </div>

                                {/* ================= Daughter Details =================== */}


                                {/* ================= Brother Details =================== */}
                                <div className="row">
                                    {/* ======== Left row ========= */}
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <h4 className='datanamfo'><b>Brother Name</b></h4>
                                            </div>
                                            <div className="col-md-1"><h4>:</h4></div>
                                            <div className="col-md-7">
                                                <h4 className='datanamfo'></h4>
                                            </div>
                                        </div>
                                    </div>

                                    {/* ======== Left row ========= */}

                                    {/* ======== right row ========= */}
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <h4 className='datanamfo'><b>Contact Number</b></h4>
                                            </div>
                                            <div className="col-md-1"><h4>:</h4></div>
                                            <div className="col-md-7">
                                                <h4 className='datanamfo'></h4>
                                            </div>
                                        </div>
                                    </div>

                                    {/* ======== right row ========= */}


                                </div>

                                {/* ================= Brother Details =================== */}


                                {/* ================= Sister Details =================== */}
                                <div className="row">
                                    {/* ======== Left row ========= */}
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <h4 className='datanamfo'><b>Sister Name</b></h4>
                                            </div>
                                            <div className="col-md-1"><h4>:</h4></div>
                                            <div className="col-md-7">
                                                <h4 className='datanamfo'></h4>
                                            </div>
                                        </div>
                                    </div>

                                    {/* ======== Left row ========= */}

                                    {/* ======== right row ========= */}
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <h4 className='datanamfo'><b>Contact Number</b></h4>
                                            </div>
                                            <div className="col-md-1"><h4>:</h4></div>
                                            <div className="col-md-7">
                                                <h4 className='datanamfo'></h4>
                                            </div>
                                        </div>
                                    </div>

                                    {/* ======== right row ========= */}


                                </div>

                                {/* ================= Sister Details =================== */}

                                {/* ================= Uncle Details =================== */}
                                <div className="row">
                                    {/* ======== Left row ========= */}
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <h4 className='datanamfo'><b>Uncle Name</b></h4>
                                            </div>
                                            <div className="col-md-1"><h4>:</h4></div>
                                            <div className="col-md-7">
                                                <h4 className='datanamfo'></h4>
                                            </div>
                                        </div>
                                    </div>

                                    {/* ======== Left row ========= */}

                                    {/* ======== right row ========= */}
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <h4 className='datanamfo'><b>Contact Number</b></h4>
                                            </div>
                                            <div className="col-md-1"><h4>:</h4></div>
                                            <div className="col-md-7">
                                                <h4 className='datanamfo'></h4>
                                            </div>
                                        </div>
                                    </div>

                                    {/* ======== right row ========= */}


                                </div>

                                {/* ================= Uncle Details =================== */}


                                {/* ================= Other Details =================== */}
                                <div className="row">
                                    {/* ======== Left row ========= */}
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <h4 className='datanamfo'><b>Other Name</b></h4>
                                            </div>
                                            <div className="col-md-1"><h4>:</h4></div>
                                            <div className="col-md-7">
                                                <h4 className='datanamfo'></h4>
                                            </div>
                                        </div>
                                    </div>

                                    {/* ======== Left row ========= */}

                                    {/* ======== right row ========= */}
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <h4 className='datanamfo'><b>Contact Number</b></h4>
                                            </div>
                                            <div className="col-md-1"><h4>:</h4></div>
                                            <div className="col-md-7">
                                                <h4 className='datanamfo'></h4>
                                            </div>
                                        </div>
                                    </div>

                                    {/* ======== right row ========= */}


                                </div>

                                {/* ================= Other Details =================== */}

                                {/* ================== Other Family Details ============ */}


                                {/* ================================= */}
                                <hr />
                                {/* ============================ */}

                                {/* ========================== Social Links =============== */}
                                <h4 className='resultfound'>Social Links</h4>
                                <div className="row">

                                    <div className="iconlist">
                                        <ul>
                                            <li><a href="javascript:void(0)"><img className='iconlogo' src={facebook} alt="face" /></a></li>
                                            <li><a href="javascript:void(0)"><img className='iconlogo' src={linkedin} alt="face" /></a></li>
                                            <li><a href="javascript:void(0)"><img className='iconlogo' src={twitter} alt="face" /></a></li>
                                            <li><a href="javascript:void(0)"><img className='iconlogo' src={instagram} alt="face" /></a></li>
                                        </ul>
                                    </div>
                                </div>
                                {/* ========================== Social Links =============== */}

                                {/* =================================== */}
                                <hr />
                                {/* =================================== */}

                                {/* ==================== Amount Details ================ */}
                                <h4 className='resultfound'>ACE RAW</h4>

                                <div className="row">

                                    <div className="col-md-4">
                                        <h4 className='datanamfo'><b>Total Outstanding</b></h4>
                                    </div>
                                    <div className="col-md-1"><h4>:</h4></div>
                                    <div className="col-md-7">
                                        <h4 className='datanamfo'><b>Rs.</b></h4>
                                    </div>

                                </div>

                                <div className="row">

                                    <div className="col-md-4">
                                        <h4 className='datanamfo'><b>Max DPD</b></h4>
                                    </div>
                                    <div className="col-md-1"><h4>:</h4></div>
                                    <div className="col-md-7">
                                        <h4 className='datanamfo'></h4>
                                    </div>

                                </div>

                                <div className="row">

                                    <div className="col-md-4">
                                        <h4 className='datanamfo'><b>Financier From</b></h4>
                                    </div>
                                    <div className="col-md-1"><h4>:</h4></div>
                                    <div className="col-md-7">
                                        <h4 className='datanamfo'></h4>
                                    </div>

                                </div>

                                <div className="row">

                                    <div className="col-md-4">
                                        <h4 className='datanamfo'><b>Last Due+ DPD</b></h4>
                                    </div>
                                    <div className="col-md-1"><h4>:</h4></div>
                                    <div className="col-md-7">
                                        <h4 className='datanamfo'></h4>
                                    </div>

                                </div>


                                <div className="row">

                                    <div className="col-md-4">
                                        <h4 className='datanamfo'><b>Amount</b></h4>
                                    </div>
                                    <div className="col-md-1"><h4>:</h4></div>
                                    <div className="col-md-7">
                                        <h4 className='datanamfo'><b>Rs. </b></h4>
                                    </div>

                                </div>
                                {/* ==================== Amount Details ================ */}



                            </div>
                        ))}



                        <button className='btnmodalclosemng' onClick={closeModal}><span>&#10006;</span></button>
                    </Modal>
                </div>


                {/* ==================== Modal Here =============== */}
            </div>
        </div>
    )
}

export default CustomerDetail;