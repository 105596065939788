import logo from './logo.svg';
import './App.css';
import { BrowserRouter } from "react-router-dom";
import { CustomRoutes } from './CustomRoutes';

function App() {
  return (
    <>
      <BrowserRouter>
        <CustomRoutes />
      </BrowserRouter>
    </>
  );
}

export default App;
