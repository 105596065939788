import { useRef, useState } from "react";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { pos_data } from "../utils/CommonUtils";
import { useNavigate } from "react-router-dom";
import Modal from 'react-modal';
import outData from '../images/outdata.jpg'





const SearchSchema = Yup.object().shape({
    phone: Yup.string()
        .min(2, 'Too Short!')
        .max(10, 'Too Long!'),
    //  .required('Required'),
    fullName: Yup.string()
        .min(3, 'Too Short!')
        .max(50, 'Too Long!'),
    email: Yup.string().email('Invalid email'),
    panNumber: Yup.string()
        .min(3, 'Too Short!')
        .max(10, 'Too Long!'),
});

export default function SearchForm(props) {

    const [modalIsOpen, setIsOpen] = useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }


    const navigate = useNavigate();
    const [loading, setloading] = useState(false);

    const clickdata = () => {
        setloading(true)
    }

    return (
        <>
            <Formik
                initialValues={{
                    phone: '',
                    fullName: '',
                    email: '',
                    panNumber: '',
                }}
                validationSchema={SearchSchema}
                onSubmit={async values => {
                    // same shape as initial values
                    values['feature'] = "SEARCH_CUSTOMER";
                    // console.log(values);
                    console.log(props.clickdata(true))
                    let apiData = await pos_data('acenna-search', values);
                    props.clickdata(false);
                    if (apiData && apiData.status && apiData.status == 'success' && apiData.data.length > 0) {
                        navigate('/customer_detail', {
                            state: { customerData: apiData.data }
                        });
                    } else {
                        setIsOpen(true);
                    }

                }}
            >

                {({ errors, touched }) => (

                    <Form className="form-inline">
                        <div className="form-group">
                            <label htmlFor="phone">Phone</label> <br />
                            <Field name="phone" className="inputcomplex" />
                            {errors.phone && touched.phone ? (
                                <div className="errorline">{errors.phone}</div>
                            ) : null}
                        </div>
                        <div className="form-group">
                            <label htmlFor="fullName">Full Name</label> <br />
                            <Field className="inputcomplex" label='fullName' name="fullName" />
                            {errors.fullName && touched.fullName ? (
                                <div className="errorline">{errors.fullName}</div>
                            ) : null}
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email</label> <br />
                            <Field name="email" type="email" className="inputcomplex" />
                            {errors.email && touched.email ? <div className="errorline">{errors.email}</div> : null}
                        </div>
                        <div className="form-group">
                            <label htmlFor="panNumber">PAN Number</label> <br />
                            <Field name="panNumber" type="text" className="inputcomplex" />
                            {errors.panNumber && touched.panNumber ? <div className="errorline">{errors.panNumber}</div> : null}
                        </div>
                        {/* <button type="submit">Submit</button> */}
                        <br />
                        <br />
                        <div className="enterbtnherh">
                            <button className="btn btnsimp" type="submit" >Enter</button>
                        </div>
                    </Form>
                )
                }
            </Formik >

            <div>
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    className="modalstylechange"
                    contentLabel="Example Modal"
                    ariaHideApp={false}>
                    <div className="mainmodaldata">
                        <div className="noDataFound">
                            <img src={outData} alt="outdata" />
                            {/* <p>No Data Found</p> */}
                            <button className='btnclosebtnh' onClick={closeModal}><span>&#10006;</span></button>
                        </div>
                    </div>
                </Modal>
            </div>
        </>
    )

}
