import React, { useEffect, useState } from "react";
// import "../App.css";
import { login_Auth } from "../utils/CommonUtils";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate, Outlet } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";
import Loader from "./Loader";

// Creating schema
const schema = Yup.object().shape({
  email: Yup.string()
    .required("Email is a required field")
    .email("Invalid email format"),
  password: Yup.string()
    .required("Password is a required field")
    .min(8, "Password must be at least 8 characters"),
});

function LoginPage(props) {
  const [loginError, setLoginError] = useState(false);
  const [loading, setIsLoading] = useState(false);

// console.log("props--=",props.loggedIn);

  console.log("loading----", loading);
  const navigate = useNavigate();


  return (
    <>
    {/* <Outlet/> */}
      <LoadingOverlay active={loading} spinner={<Loader />}>
        <div className="mainLoginContainer">
          <div className="login-form-wrap">
            <h4 className="brandKlimo">KLIMO</h4>
            <h2>Welcome to Klimo</h2>
            {loginError ? (
              <div className="errorNotification">
                <p>Please Enter Valid Email or Password</p>
                <span onClick={()=>{setLoginError(false)}}>&#10006;</span>
              </div>
            ) : null}
            <Formik
              validationSchema={schema}
              initialValues={{ email: "", password: "" }}
              onSubmit={async (values) => {
                let data = {
                  feature: "LOGIN",
                  email: values.email,
                  password: values.password,
                };
                setIsLoading(true);
                let loginData = await login_Auth(data);
                setIsLoading(false);
                if (loginData.status === "success") {
                  // alert("login success--")
                  navigate("/search");
                } 
                else if(loginData.status == "undefined" || loginData.status == "error" || loginData.status == null) {
                  setLoginError(true);
                }
                // console.log(" loginData --- ", loginData);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <div className="login">
                  <div className="form">
                    {/* Passing handleSubmit parameter tohtml form onSubmit property */}
                    <form
                      noValidate
                      onSubmit={handleSubmit}
                      className="login-form"
                    >
                      {/* Our input html with passing formik parameters like handleChange, values, handleBlur to input properties */}
                      <p className="inputPara">
                        <input
                          type="email"
                          name="email"
                          required
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          placeholder="Enter Email Id"
                          className="form-control inp_text"
                          id="email"
                        />
                        <i className="validation">
                          <span />
                          <span />
                        </i>

                        <p className="error">
                          {errors.email && touched.email && errors.email}
                        </p>
                      </p>
                      {/* If validation is not passed show errors */}

                      {/* Our input html with passing formik parameters like handleChange, values, handleBlur to input properties */}
                      <p className="inputPara">
                        <input
                          type="password"
                          name="password"
                          required
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password}
                          placeholder="Enter Password"
                          className="form-control"
                          id="password"
                        />
                        <i className="validation">
                          <span />
                          <span />
                        </i>
                        <p className="error">
                          {errors.password &&
                            touched.password &&
                            errors.password}
                        </p>
                      </p>
                      {/* If validation is not passed show errors */}

                      {/* Click on submit button to submit the form */}
                      <p>
                        <button type="submit" id="login">
                          Login
                        </button>
                      </p>
                    </form>
                  </div>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </LoadingOverlay>
      {/* Wrapping form inside formik tag and passing our schema to validationSchema prop */}
    </>
  );
}

export default LoginPage;
