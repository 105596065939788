import React, { useState, useEffect, useMemo } from "react";
import "../App.css";
import Table from "./Table_help";
import Second_page from "./CustomerDetail";
import { useNavigate, Routes, Route, Link } from "react-router-dom";
import SearchForm from "./SearchForm";
import LoadingOverlay from 'react-loading-overlay';
import Alert from 'react-bootstrap/Alert';
import Loader from "./Loader";




function Search() {
    const navigate = useNavigate();
    const [loading, setloading] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(true);
    const [showSuccessAlert, setShowSuccessAlert] = useState(true);
    const clickdata = (loading) => {
        // console.log(loading)
        setloading(loading)

    }
    return (

        <>
            <LoadingOverlay
                active={loading}
                spinner={<Loader/>}
            >
                <div className="mainwapperdiv">
                    <div className="logOutBtnHere">
                    <button class="btn btn-1 btn-sep icon-info"><Link to="/">LogOut</Link></button>
                    </div>
                    <h4 className="brandheading">KLIMO</h4>
                    <div className="container someconther">
                        <div className="col-lg-8">
                            {showErrorAlert &&
                                <Alert variant="danger" onClose={() => setShowErrorAlert(false)} dismissible>
                                    {/* {errorAlertMsg} */}
                                    Testing
                                </Alert>
                            }


                        </div>
                        <div className="maincomphed">
                            <div className="maintoptemng">
                                <h4 className="searchbyh">Hello, Search By...</h4>
                            </div>
                            <div className="tableheadfirst">
                                <SearchForm clickdata={clickdata} />
                            </div>
                        </div>
                    </div>
                </div>
            </LoadingOverlay>
        </>


    );
}

export default Search;
