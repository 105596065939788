import React, { Suspense, lazy, useEffect, useState } from "react";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CustomerDetail from "./component/CustomerDetail";
import Search from "./component/Search";
import LoginPage from "./component/LoginPage";

export default function BasePage() {
  let loggedIn = true;

  if (loggedIn) {
    return (
      <>
        {/* <Navigate to="/search" /> */}
        <Outlet />
        <LoginPage loggedIn={loggedIn} />;
      </>
    );
  } else {
    return (
      <>
      {/* <Navigate to ="/"/> */}
      {/* <LoginPage loggedIn={loggedIn} />; */}
      </>
    )
  }
}
