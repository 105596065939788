/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
//import { shallowEqual } from "react-redux";
import { useSelector } from "react-redux";

import BasePage from "./BasePage";
import Search from "./component/Search";
import CustomerDetail from "./component/CustomerDetail";
import LoginPage from "./component/LoginPage";
//  import { Logout, AuthPage } from "./modules/Auth";
//  import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";

export function CustomRoutes() {
  //    let { loggedIn } = useSelector(state => state.entities.auth);
  //    const user = useSelector(state => state.entities.auth.data);
  //    const isLogs = useSelector(state => state.entities.auth.showLogs);
  //    console.log('isLogs:', isLogs);

  let loggedIn = true;

  return (
    <Routes>
      {!loggedIn ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>{/* <AuthPage /> */}</Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        //  <Redirect from="/auth" to="/" />
        <></>
      )}

      {/* <Route path="/error" component={ErrorsPage} />
       <Route path="/logout" component={Logout} /> */}

      {!loggedIn ? (
        /*Redirect to `/auth` when user is not authorized*/
        //  <Redirect to="/auth/login" />
        <></>
      ) : (
        //    <BasePage />
        <>
          <Route path="/" element={<BasePage />}>
            <Route path="Search" element={<Search />} />
            <Route path="customer_detail" element={<CustomerDetail />} />
          </Route>
        </>
      )}
    </Routes>
  );
}
