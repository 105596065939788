import React from 'react';
import Loading from "../images/loading.gif"

function Loader() {
  return (
    <div className='loaderImage'>
        <img src={Loading} alt="loader"/>
        <h4>Loading your Data...</h4>
    </div>
  )
}

export default Loader